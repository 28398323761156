<template>
  <div :class="classes">
    <button class="input-base-item-inner">
      <div class="input-base-item-inner-header">
        <div>
          {{ order }}
          <span>{{ title }}</span>
        </div>

        <button v-if="helpComponent" class="input-base-item-inner-help" @click="openHelpPopup">
          <img :src="require('@/assets/help.svg')" />
        </button>
      </div>
      <component ref="content" v-if="component" :is="component" class="input-base-item-inner-content" />
    </button>

    <div ref="control" v-if="active && (hasNext || last)" class="input-base-button-wrapper">
      <div class="input-base-button-inner">
          <button @click="$emit('nextItem')" :class="{ 'input-base-button': true, 'last': last }">
            {{ hasNext ? 'Weiter' : 'Fertig' }}
          </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "InputContainer",
  props: {
    order: Number,
    hasNext: Boolean,
    title: String,
    active: Boolean,
    complete: Boolean,
    last: Boolean,
    component: null,
    helpComponent: null
  },
  watch: {
    active(newValue) {
      if (newValue) {
        this.doOpenAnimation();
      }
    }
  },
  computed: {
    classes() {
      return {
        'input-base-item': true,
        'active': this.active,
        'complete': this.complete,
        'last': this.last
      }
    }
  },
  methods: {
    ...mapActions(['updatePopupHelpComponent']),
    openHelpPopup() {
        this.updatePopupHelpComponent(this.helpComponent);
    },
    doOpenAnimation() {
      if (this.active && this.$refs.content) {
        let content = this.$refs.content.$el;
        content.style.maxHeight = content.scrollHeight + 'px';
      }
    },
    doScrollTo() {
      if (this.$refs.control) {
        let content = this.$refs.content.$el;
        content.style.maxHeight = 'min-content';

        this.$refs.control.scrollIntoView({ block: 'end',  behavior: 'smooth' });
      }
    }
  },
  mounted() {
    if (!this.complete) {
      if(document.readyState === 'complete') {
        if (this.$refs.content)
          this.$refs.content.$el.addEventListener('transitionend', this.doScrollTo);

        this.doOpenAnimation();
      } else {
        window.addEventListener('load', this.doOpenAnimation);
      }
    }
  },
  updated() {
    if (!this.complete) {
      if(document.readyState === 'complete') {
        if (this.$refs.content)
          this.$refs.content.$el.addEventListener('transitionend', this.doScrollTo);

        this.doOpenAnimation();
      } else
        window.addEventListener('load', this.doOpenAnimation);
    }
  },
  destroyed() {
    window.removeEventListener('load', this.doOpenAnimation);
    window.removeEventListener('transitionend', this.doScrollTo);
  }
};
</script>

<style lang="scss">
  .input-base-item {
    width: 100%;
    margin-bottom: 8px;

    &.active {
      margin-bottom: 36px;

      .input-base-item-inner {
        position: relative;
        padding: 10px 32px 24px 32px;

        border: 1px solid #E3000B;

        @media (max-width: $width-tablet) {
          padding: 10px 16px 24px 16px;
        }

        .input-base-item-inner-header {
          color: #E3000B;

          span {
            color: #293136;
          }
        }

        .input-base-item-inner-content {
          margin-top: 24px;
        }
      }
    }

    &.complete {
      &:not(:last-child) {
        position: relative;

        &:after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          width: 1px;
          height: 16px;
          margin: auto;

          background-color: #CAD3D9;
        }
      }
      margin-bottom: 16px;

      .input-base-item-inner {
        padding: 10px 32px 24px 32px;

        @media (max-width: $width-tablet) {
          padding: 10px 16px 24px 16px;
        }

        .input-base-item-inner-content {
          margin-top: 24px;
          max-height: 100%;
        }
      }
    }

    &-inner {
      display: block;
      width: 100%;
      padding: 10px 32px;

      background-color: #FFFFFF;
      border: 1px solid #CAD3D9;
      text-align: left;

      &-header {
        display: flex;
        justify-content: space-between;

        font-size: 1rem;
        font-family: futura-pt-condensed, sans-serif;
        font-weight: bold;
        color: #CAD3D9;

        @media (max-width: $width-tablet) {
          font-size: 1.313rem;
        }

        span {
          color: #69777F;
        }
      }

      &-content {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
      }

      &-help {
        padding: 0;

        background: transparent;
        border: none;
        cursor: pointer;
      }
    }
  }

  .input-base-button-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 32px;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: -32px;
      width: 1px;
      height: 32px;
      left: 0;
      right: 0;
      margin: 0 auto;

      background-color: #E3000B;
    }

    .input-base-button-inner {
      display: inline-block;
      width: auto;
      padding: 4px;

      border: 1px solid #E3000B;

      .input-base-button {
        padding: 8px 16px;

        background-color: #E3000B;
        border: none;
        color: #FFFFFF;

        &.last {
          background-color: transparent;
          color: #E3000B;
        }

        font-family: futura-pt-condensed, sans-serif;
        font-size: 1rem;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
      }
    }
  }
</style>