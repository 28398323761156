<template>
  <div class="imprint">
    <p class="imprint-name">Forschungsgesellschaft VMM-Spannbetonplatten GbR</p>
    <p class="imprint-address">Im Fußtal 2 · D-50171 Kerpen</p>
    <p class="imprint-contact-information">Tel.: 02237 / 53435 · Fax: 02237 / 53788</p>
    <p class="imprint-version">VMM Bemessung V 4.2</p>
    <p class="imprint-original-creators"><span class="underline">DRAHEIM</span> INGENIEURE Planungsgesellschaft mbH</p>
  </div>
</template>

<script>
export default {
  name: "Imprint"
}
</script>

<style lang="scss">
.imprint {
  font-family: futura-pt, sans-serif;
  font-size: 0.571rem;
  color: #293136;

  @media (max-width: $width-tablet) {
    font-size: 0.75rem;
    line-height: 0.9rem;
  }

  .underline {
    text-decoration: underline;
  }

  > * {
    line-height: 0.8rem;
    margin-bottom: 0;
  }

  &-name {
    font-weight: bold;
  }

  &-contact-information {
    margin-bottom: 16px;
  }
}
</style>