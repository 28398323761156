<template>
  <tr class="result-utilization-item">
    <td class="ok"> {{ title }}</td>
    <td :class="{ 'ok': !isExceedingLoad(result), 'exceed': isExceedingLoad(result) }">{{ result.load.value }}</td>
    <td :class="{ 'ok': !isExceedingShearForce(result), 'exceed': isExceedingShearForce(result) }">{{ result.shearForce.value }}</td>
    <td :class="{ 'ok': !isExceedingMoment(result), 'exceed': isExceedingMoment(result) }">{{ result.moment.value }}</td>
  </tr>
</template>

<script>
export default {
  name: "ResultUtilizationItem",
  props: {
    title: String,
    result: Object
  },
  methods: {
    isExceedingMoment(result) {
      return result.moment.realValue > 1;
    },
    isExceedingShearForce(result) {
      return result.shearForce.realValue > 1;
    },
    isExceedingLoad(result) {
      return !result.load.realValue;
    },
  }
};
</script>

<style lang="scss">
.pre-dimension-main {
  .result-utilization-item {
    &:last-child {
      td {
        padding-top: 16px !important;
      }
    }
  }

  .result-wrapper tbody tr td {
    &.ok {
      color: #59915D;
    }

    &.exceed {
      color: #E3000B;
    }
  }
}
</style>