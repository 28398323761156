<template>
  <tr class="result-primary-item" :style="item.ceilingThickness === 12.0 || item.ceilingThickness === 14.0 ? 'display: none' : ''">
    <td class="selection-input center">
      <input
          ref="selection"
          type="radio"
          name="primaryResult"
          :id="'primaryResult' + index"
          :checked="selected"
          @change="$emit('itemSelected', index)"
      >
      <label :for="'primaryResult' + index">{{ item.ceilingThickness.toFixed(2).replace('.', ',') }}</label>
    </td>
    <td>
      <button
          v-if="item.results.vsd"
          @click="$emit('itemResultSelected', { index, resultIndex: 'vsd' })"
          :class="{ 'selected': selected && selectedResultIndex === 'vsd', 'exceeded': isExceeding(item.results.vsd.utilization) }"
      >
        {{ getBiggerResultValue(item.results.vsd.utilization) }}
      </button>
    </td>
    <td>
      <button
          v-if="item.results.lepd"
          @click="$emit('itemResultSelected', { index, resultIndex: 'lepd' })"
          :class="{ 'selected': selected && selectedResultIndex === 'lepd', 'exceeded': isExceeding(item.results.lepd.utilization) }"
      >
        {{ getBiggerResultValue(item.results.lepd.utilization) }}
      </button>
    </td>
  </tr>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
  name: "ResultPrimaryItem",
  props: {
    index: Number,
    selectedResultIndex: String,
    selected: Boolean
  },
  computed: {
    ...mapGetters(['results', 'options']),
    item() {
      return this.results.preDimension[this.index];
    }
  },
  methods: {
    isExceeding(result) {
      return !result.load.realValue || result.moment.realValue > 1 || result.shearForce.realValue > 1;
    },
    getBiggerResultValue(result) {
      if (this.options.fireResistance === 'f90' && (this.item.ceilingThickness === 12 || this.item.ceilingThickness === 14))
        return '\u3000';

      if (this.isExceeding(result))
        return 'überschritten';

      return result.moment.realValue > result.shearForce.realValue ?
          result.moment.value :
          result.shearForce.value;
    }
  }
};
</script>

<style lang="scss">
.pre-dimension-main {
  .result-container {
    tbody {
      .result-primary-item {
        &:first-child {

        }
      }

      tr {
        &.selected {
          .selection-input {
            font-weight: bold;
            text-decoration: underline;
          }
        }
      }

      td {
        min-width: 100px;

        @media (max-width: $width-tablet) {
          min-width: initial;
        }

        button {
          width: auto;
          height: 100%;
          padding: 4px;

          font-family: futura-pt, sans-serif;
          font-size: 0.762rem;
          background: transparent;
          border: none;
          text-align: left;
          font-weight: bold;
          color: #59915D;
          cursor: pointer;

          &.exceeded {
            color: rgba(227, 0, 11, 1);
          }

          &.selected {
            background-color: rgba(89, 145, 93, 0.2);

            &.exceeded {
              background-color: rgba(227, 0, 11, 0.2);
            }
          }
        }

        input[type="radio"] {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;

          display: inline-block;
          width: 12px;
          height: 12px;
          padding: 2px;
          margin: 0;

          background-clip: content-box;
          border: 1px solid #bbbbbb;
          background-color: transparent;
          border-radius: 50%;
        }

        input[type="radio"]:checked {
          border: 1px solid #E3000B;
          background-color: #E3000B;
        }

        label {
          margin-left: 8px;

          font-family: futura-pt-book, sans-serif;
          font-size: 0.762rem;
        }
      }
    }
  }
}
</style>