<template>
  <main class="pre-dimension-main" id="main">
    <h6 v-if="isMobile" class="page-title">VORBEMESSUNG VMM-SPANNBETONFERTIGDECKE</h6>

    <div :class="{ 'grid two-columns': true, 'mobile': isMobile, 'hide-result': isMobile || isMobile && !done }">
      <div class="grid-item">
        <InputManager result="preDimension" @done="done = true" :items="inputItems"/>
        <Imprint v-if="!isMobile" />
      </div>

      <div class="grid-item">
        <div v-if="!isMobile || isMobile && done" class="result">
          <Result />
        </div>
        <Imprint v-if="isMobile" />
      </div>
    </div>
  </main>
</template>

<script>
import {mapGetters} from 'vuex';
import InputManager from "@/components/InputManager";
import Result from "@/components/PreDimension/Result";
import Imprint from "@/components/Imprint";
export default {
  name: "PreDimension",
  components: {Imprint, Result, InputManager},
  data() {
    return {
      done: false,
      inputItems: [
        { title: 'Grundeinstellungen', component: () => import('@/components/PreDimension/BasisSettings'), helpComponent: () => import('@/components/PreDimension/Help/BasicSettingsHelp') },
        { title: 'Maße', component: () => import('@/components/PreDimension/Dimensions'), helpComponent: () => import('@/components/PreDimension/Help/DimensionsHelp') },
        { title: 'Einsatzbereich', component: () => import('@/components/PreDimension/FieldOfApplication'), helpComponent: () => import('@/components/PreDimension/Help/FieldOfApplicationHelp') },
        { title: 'Nutzlast', component: () => import('@/components/PreDimension/Payload'), helpComponent: () => import('@/components/PreDimension/Help/PayloadHelp') },
        { title: 'Durchbrüche', component: () => import('@/components/PreDimension/Breakthroughs') }
      ]
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  }
};
</script>

<style lang="scss">
.pre-dimension-main {
  >.grid {
    grid-column-gap: 160px;
  }

  .page-title {
    margin: 15px 0 32px 0;

    font-size: 1.75rem;
    line-height: 2.2rem;
    color: #E3000B;
  }

  .hide-result {
    grid-template-columns: 1fr;
  }

  .input-manager {
    margin-bottom: 64px;
  }

  .grid {
    &.mobile {
      .result {
        position: initial;

        width: calc(100% + 16px * 2);
        padding: 32px 16px 0 16px;
        margin-left: -16px;
        overflow: hidden;
      }
    }
  }

  .grid-item {
    position: relative;
  }

  .result {
    padding: 32px 32px 0 32px;
    margin-bottom: 64px;

    background-color: #FFFFFF;
    border: 1px solid #CAD3D9;

    position: fixed;
    top: 100px;

    overflow-y: auto;
    max-height: calc(100% - 120px);

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}
</style>