<template>
  <div class="result-container">
    <div class="title-container">
      <h6 class="title">Erforderliche Deckenstärke / Ausnutzung</h6>

      <button class="title-help" @click="openHelpPopup">
        <img :src="require('@/assets/help.svg')" />
      </button>
    </div>

    <table class="result-wrapper main spacing">
      <thead>
        <tr>
          <td class="underline">Deckenstärke (cm)</td>
          <td class="space-between underline"><img :src="require('@/assets/vsd.svg')">VMM VSD</td>
          <td class="space-between underline"><img :src="require('@/assets/l_epd.svg')">VMM-L EPD</td>
        </tr>
      </thead>
      <tbody>
        <ResultPrimaryItem
            v-for="(item, index) in results.preDimension"
            :class="{ 'border-bottom': item.borderBottom, 'selected': selectedItemIndex === index }"
            :key="index"
            :index="index"
            :selected="selectedItemIndex === index"
            :selectedResultIndex="selectedItemResultIndex"
            @itemSelected="setSelectedItem"
            @itemResultSelected="setSelectedItemResult" />
      </tbody>
    </table>

    <table v-if="selectedItemResult" class="result-wrapper secondary spacing">
      <thead>
        <tr>
          <td class="green">{{ selectedItemName }}</td>
          <td>Auflast</td>
          <td>Querkraft</td>
          <td>Moment²</td>
        </tr>
      </thead>
      <tbody>
        <ResultSecondaryItem :title="'Vorhanden'" :result="selectedItemResult.present" />
        <ResultSecondaryItem class="border-bottom" :title="'Zulässig'" :result="selectedItemResult.allowed" />
        <ResultUtilizationItem :title="'Ausnutzung'" :result="selectedItemResult.utilization" />
      </tbody>
    </table>

    <div v-if="selectedItemIndex !== null" class="output-result-list">
      <button @click="pdfPredimensionResults" class="output-result-item">
        <img :src="require('@/assets/file_download.svg')">
        <span>Nachweis herunterladen</span>
      </button>
      <button @click="printPredimensionResults" class="output-result-item">
        <img :src="require('@/assets/print.svg')">
        <span>Nachweis drucken</span>
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import ResultPrimaryItem from "@/components/PreDimension/ResultPrimaryItem";
import ResultUtilizationItem from "@/components/PreDimension/ResultUtilizationItem";
import ResultSecondaryItem from "@/components/PreDimension/ResultSecondaryItem";
export default {
  name: "Result",
  components: {ResultSecondaryItem, ResultUtilizationItem, ResultPrimaryItem},
  data() {
    return {
      selectedItemIndex: null,
      selectedItemResultIndex: '',
      helpComponent: () => import('@/components/PreDimension/Help/ResultHelp')
    }
  },
  computed: {
    ...mapGetters(['results']),
    filteredResults() {
      return this.results.preDimension.filter(x => x.ceilingThickness !== 12 && x.ceilingThickness !== 14);
    },
    selectedItem() {
      if (this.selectedItemIndex === null)
        return null;

      return this.results.preDimension[this.selectedItemIndex];
    },
    selectedItemResult() {
      if (this.selectedItemIndex === null || this.selectedItemResultIndex === null)
        return null;

      return this.results.preDimension[this.selectedItemIndex].results[this.selectedItemResultIndex];
    },
    selectedItemName() {
      let name = "";

      if (this.selectedItemResult) {
        if(this.selectedItemResultIndex === "vsd") {
          name = "VMM VSD";
        } else if(this.selectedItemResultIndex === "lscd") {
          name = "VMM-L SCD";
        } else {
          name = "VMM-L EPD";
        }

        name += " " + this.selectedItem.ceilingThickness + "¹";
      }

      return name;
    }
  },
  methods: {
    ...mapActions(['calculatePreDimensionResults', 'printPredimensionResults', 'pdfPredimensionResults', 'updatePreDimensionSelectedResult', 'updatePopupHelpComponent']),
    openHelpPopup() {
      this.updatePopupHelpComponent(this.helpComponent);
    },
    setSelectedItem(index) {
      this.selectedItemIndex = index;

      let result = this.results.preDimension[index];
      if (result.results.vsd)
        this.selectedItemResultIndex = 'vsd';
      else if (result.results.lepd)
        this.selectedItemResultIndex = 'lepd';
      else
        this.selectedItemResultIndex = null;

      console.log(this.selectedItemResult);

      if (this.selectedItemResult !== null)
        this.updatePreDimensionSelectedResult({ key: this.selectedItemResultIndex, value: this.selectedItemResult.id });
    },
    setSelectedItemResult(data) {
      if (this.selectedItemIndex === data.index && this.selectedItemResultIndex === data.resultIndex) {
        this.selectedItemIndex = null;
        this.selectedItemResultIndex = null;
      } else {
        this.selectedItemIndex = data.index;
        this.selectedItemResultIndex = data.resultIndex;
      }

      if (this.selectedItemResult !== null)
        this.updatePreDimensionSelectedResult({ key: this.selectedItemResultIndex, value: this.selectedItemResult.id });
    },
    isExceeding(item) {
      console.log(item);
    }
  },
  mounted() {
    const rows = this.results.preDimension;
    for (let i = 0; i < rows.length; i++) {
      //const row = this.results.preDimension[i];
      //console.log(row);
    }
  }
};
</script>

<style lang="scss">
.pre-dimension-main {
  .result-container {
    > .title-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;

      .title {
        font-size: 1rem;
        line-height: 1rem;
        color: #E3000B;
        margin-bottom: 0;
      }

      .title-help {
        background: transparent;
        border: none;
        cursor: pointer;
      }
    }

    .spacing {
      margin-bottom: 48px;
    }

    .result-wrapper {
      display: grid;
      width: 100%;

      font-size: 0.762rem;

      &.main {
        grid-template-columns: 3fr 1fr 1fr;
      }

      &.secondary {
        grid-template-columns: 3fr 1fr 1fr 1fr;
      }

      @media (max-width: $width-tablet) {
        &.main {
          grid-template-columns: 2fr 1fr 1fr;
        }

        &.secondary {
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }

        font-size: 0.75rem;
      }

      thead, tbody, tr {
        display: contents;
      }

      tr {
        height: auto;

        &.border-bottom {
          td {
            border-bottom: 1px solid #69777F;
          }
        }

        td {
          width: 100%;
          height: 100%;
          padding: 4px 0 4px 0;

          &.underline {
            text-decoration: underline;
          }

          &.green {
            color: #59915D;
          }

          &.center {
            display: flex;
            align-items: center;
          }
        }
      }

      thead {
        font-family: futura-pt-condensed, sans-serif;
        color: #293136;
        font-weight: bold;

        td {
          display: flex;
          align-items: flex-end;
          padding: 8px 0 16px 0;

          border-bottom: 1px solid #69777F;

          img {
            width: 60px;
            height: 24px;
          }

          &.space-between {
            flex-direction: column;
            align-items: initial;
            gap: 8px;
          }
        }
      }

      tbody {
        font-family: futura-pt-book, sans-serif;
      }
    }

    .output-result-list {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;

      &:before {
        content: '';
        position: absolute;
        left: -32px;
        width: calc(100% + 64px);
        height: 1px;

        background-color: #CAD3D9;
      }

      .output-result-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        font-family: futura-pt-condensed, sans-serif;

        &:first-child {
          padding: 16px 32px 16px 0px;
        }

        &:last-child {
          padding: 16px 0px 16px 32px;

          border-right: none;
        }

        font-size: 0.762rem;
        color: #293136;
        font-weight: bold;
        border: none;
        background: none;
        cursor: pointer;

        border-right: 1px solid #CAD3D9;

        @media (max-width: $width-tablet) {
          font-size: 1rem;
        }

        img {
          width: 24px;
          height: 24px;
          margin-bottom: 8px;
        }
      }
    }
  }
}
</style>