<template>
  <div class="input-manager">
    <InputContainer
        v-for="(item, index) in items"
        :active="currentInputIndex === index"
        :complete="complete[index] !== undefined"
        :last="(index + 1) === items.length"
        :key="index"
        :order="index + 1"
        :hasNext="(index + 1) < items.length"
        :title="item.title"
        :component="item.component"
        :help-component="item.helpComponent"
        @nextItem="changeItem" />
  </div>
</template>

<script>
import InputContainer from "@/components/InputContainer";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "InputManager",
  components: {InputContainer},
  props: {
    result: String,
    items: Array
  },
  data() {
    return {
      currentInputIndex: 0,
      complete: {}
    }
  },
  computed: {
    ...mapGetters(['done']),
  },
  methods: {
    ...mapActions(['updateDone']),
    changeItem() {
      if ((this.currentInputIndex + 1) === this.items.length) {
        this.complete[this.currentInputIndex] = true;
        this.currentInputIndex = -1;

        this.$emit('done');
        this.updateDone({
          result: this.result,
          value: true
        });
      } else
        this.complete[this.currentInputIndex++] = true;
    }
  },
  mounted() {
    if(this.done[this.result]) {
      for (let i = 0; i < this.items.length; i++) {
        this.changeItem();
      }
    }
  }
}
</script>

<style lang="scss">
</style>