<template>
  <tr class="result-secondary-item">
    <td>{{ title }}</td>
    <td>{{ result.load.value }}</td>
    <td>{{ result.shearForce.value }}</td>
    <td>{{ result.moment.value }}</td>
  </tr>
</template>

<script>
export default {
  name: "ResultSecondaryItem",
  props: {
    title: String,
    result: Object
  }
};
</script>

<style lang="scss">
.pre-dimension-main .result-wrapper .result-secondary-item {
  &:first-child {
    td {
      padding-top: 16px !important;
    }
  }

  &:nth-child(2) {
    td {
      padding-bottom: 16px !important;
    }
  }
}
</style>